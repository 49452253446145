












import { Component, Vue } from "vue-property-decorator";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import SharedNotificationList from "@/components/Shared/Notification/List.vue";

@Component({
    components: {
        BaseWrapper,
        SharedNotificationList,
    },
})
export default class AppNotifications extends Vue {
    get notifications() {
        return this.$store.state.notifications.notifications;
    }
}
